<template>
  <v-row no-gutters align="center">
    <div
      v-show="slider?.banners?.length > 0"
      class="iconBackground swiper-button-prev-banner"
      style="left: 9svw"
      slot="button-prev"
    >
      <img
        src="@/assets/img/iconsSvg/swiperRightIcon.svg"
        alt="right icon"
        style="transform: rotate(180deg)"
      />
    </div>
    <v-main>
      <v-container>
        <v-col v-show="slider?.banners?.length > 0">
          <swiper ref="mySwiper" :options="swiperOptions">
            <swiper-slide
              v-for="banner in slider?.banners"
              :key="banner.id"
              :cssMode="true"
              :navigation="true"
              :pagination="true"
              :mousewheel="true"
              :keyboard="true"
              :modules="swiperOptions.modules"
            >
              <a :href="banner.link" v-if="banner.link !== ''" target="_blank">
                <img
                  :src="banner.html"
                  width="100%"
                  :height="$vuetify.breakpoint.lgAndDown ? '400px' : '600px'"
                  style="border-radius: 10px"
                  alt="swiper image"
                />
              </a>
              <a :href="banner.link" target="_blank" v-else>
                <img
                  :src="banner.html"
                  width="100%"
                  :height="$vuetify.breakpoint.lgAndDown ? '400px' : '600px'"
                  style="border-radius: 10px"
                  alt="swiper image"
                />
              </a>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </v-col>
      </v-container>
    </v-main>

    <div
      v-show="slider?.banners?.length > 0"
      style="right: calc(9svw - 7px)"
      class="iconBackground swiper-button-next-banner"
      slot="button-next"
    >
      <img src="@/assets/img/iconsSvg/swiperRightIcon.svg" alt="right icon" />
    </div>
  </v-row>
</template>

<script>
import { Pagination, Navigation } from "vue-awesome-swiper";
import bannerService from "../../../requests/Content/bannerService";
export default {
  data: () => ({
    swiperOptions: {
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next-banner",
        prevEl: ".swiper-button-prev-banner",
      },
      modules: [Navigation, Pagination],
    },
    slider: {},
  }),
  mounted() {
    this.getBanners();
  },
  methods: {
    async getBanners() {
      await bannerService.getSlidersByGroup("main").then((res) => {
        if (res.status == "Success") {
          this.slider = res.data?.[0];
        }
      });
    },
  },
};
</script>

<style>
.swiper-pagination-bullet {
  width: 32px;
  height: 3px;
  border-radius: 1px;
  background-color: #fed500;
}
</style>
<style scoped>
.iconBackground {
  width: 48px;
  height: 48px;
  display: grid;
  place-items: center;
  background: #fafafa;
  box-shadow: -12px 0px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
</style>