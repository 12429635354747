<template>
  <v-row no-gutters align="center" justify="center">
    <!-- SEARCH FIELD FOR MEDIUM AND LARGE DESKTOP SCREEN -->
    <div
      style="width: max-content; display: flex; align-items: center"
      v-if="!$vuetify.breakpoint.smAndDown && !$vuetify.breakpoint.md"
    >
      <div
        class="reverseIcon"
        style="z-index: 10; position: absolute; margin-left: 240px"
        @click="reverseCity"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="240"
        height="90"
        viewBox="0 0 240 90"
        fill="none"
        style="position: absolute"
      >
        <mask id="path-1-inside-1_494_353" fill="white">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5 0C2.23858 0 0 2.23858 0 5V85C0 87.7614 2.23857 90 5 90H235C237.761 90 240 87.7614 240 85V66.8239C231.738 63.0319 226 54.6861 226 45C226 35.3139 231.738 26.9681 240 23.1761V5C240 2.23858 237.761 0 235 0H5Z"
          />
        </mask>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5 0C2.23858 0 0 2.23858 0 5V85C0 87.7614 2.23857 90 5 90H235C237.761 90 240 87.7614 240 85V66.8239C231.738 63.0319 226 54.6861 226 45C226 35.3139 231.738 26.9681 240 23.1761V5C240 2.23858 237.761 0 235 0H5Z"
          fill="white"
        />
        <path
          d="M240 66.8239H241V66.1826L240.417 65.9151L240 66.8239ZM240 23.1761L240.417 24.0849L241 23.8174V23.1761H240ZM1 5C1 2.79086 2.79086 1 5 1V-1C1.68629 -1 -1 1.68629 -1 5H1ZM1 85V5H-1V85H1ZM5 89C2.79086 89 1 87.2091 1 85H-1C-1 88.3137 1.68629 91 5 91V89ZM235 89H5V91H235V89ZM239 85C239 87.2091 237.209 89 235 89V91C238.314 91 241 88.3137 241 85H239ZM239 66.8239V85H241V66.8239H239ZM240.417 65.9151C232.497 62.28 227 54.2809 227 45H225C225 55.0912 230.979 63.7838 239.583 67.7328L240.417 65.9151ZM227 45C227 35.7191 232.497 27.72 240.417 24.0849L239.583 22.2672C230.979 26.2162 225 34.9088 225 45H227ZM239 5V23.1761H241V5H239ZM235 1C237.209 1 239 2.79086 239 5H241C241 1.68629 238.314 -1 235 -1V1ZM5 1H235V-1H5V1Z"
          :fill="
            startCityError.length > 0
              ? '#ff5252'
              : isFromFieldHover
              ? '#144FA9'
              : start_city && start_city.id
              ? '#144FA9'
              : '#E2E2E2'
          "
          mask="url(#path-1-inside-1_494_353)"
        />
      </svg>
      <v-menu v-model="menu" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="backgroundTextField"
            @mouseenter="isFromFieldHover = true"
            @mouseleave="isFromFieldHover = false"
          >
            <span class="fieldName">{{ "from_label" | localize }}</span>
            <v-text-field
              v-bind="attrs"
              v-on="on"
              color="transparent"
              height="max-content"
              style="margin: 0"
              :placeholder="'from_search_ticket_placeholder' | localize"
              dense
              hide-details
              background-color="transparent"
              class="searchField"
              :value="start_city?.translations?.name"
              @input="filterStartCities"
            />
            <span class="countryName">
              {{ "country_label" | localize }}:
              {{ start_city?.country?.translations?.name }}
            </span>
          </div>
        </template>

        <div class="menuBackground">
          <v-col class="px-0 py-0">
            <p
              class="menuText"
              v-for="city in filteredStartCities"
              :key="city.id"
              style="margin: 8px 0px"
              @click="selectStartCity(city)"
            >
              {{ city?.translations?.name }},
              <span style="color: #989898">{{
                city?.country?.translations?.name
              }}</span>
            </p>
          </v-col>
        </div>
      </v-menu>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="240"
        height="90"
        viewBox="0 0 240 90"
        fill="none"
        style="
          position: absolute;
          margin-left: 260px;
          transform: rotate(180deg);
        "
      >
        <mask id="path-1-inside-1_494_353" fill="white">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5 0C2.23858 0 0 2.23858 0 5V85C0 87.7614 2.23857 90 5 90H235C237.761 90 240 87.7614 240 85V66.8239C231.738 63.0319 226 54.6861 226 45C226 35.3139 231.738 26.9681 240 23.1761V5C240 2.23858 237.761 0 235 0H5Z"
          />
        </mask>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5 0C2.23858 0 0 2.23858 0 5V85C0 87.7614 2.23857 90 5 90H235C237.761 90 240 87.7614 240 85V66.8239C231.738 63.0319 226 54.6861 226 45C226 35.3139 231.738 26.9681 240 23.1761V5C240 2.23858 237.761 0 235 0H5Z"
          fill="white"
        />
        <path
          d="M240 66.8239H241V66.1826L240.417 65.9151L240 66.8239ZM240 23.1761L240.417 24.0849L241 23.8174V23.1761H240ZM1 5C1 2.79086 2.79086 1 5 1V-1C1.68629 -1 -1 1.68629 -1 5H1ZM1 85V5H-1V85H1ZM5 89C2.79086 89 1 87.2091 1 85H-1C-1 88.3137 1.68629 91 5 91V89ZM235 89H5V91H235V89ZM239 85C239 87.2091 237.209 89 235 89V91C238.314 91 241 88.3137 241 85H239ZM239 66.8239V85H241V66.8239H239ZM240.417 65.9151C232.497 62.28 227 54.2809 227 45H225C225 55.0912 230.979 63.7838 239.583 67.7328L240.417 65.9151ZM227 45C227 35.7191 232.497 27.72 240.417 24.0849L239.583 22.2672C230.979 26.2162 225 34.9088 225 45H227ZM239 5V23.1761H241V5H239ZM235 1C237.209 1 239 2.79086 239 5H241C241 1.68629 238.314 -1 235 -1V1ZM5 1H235V-1H5V1Z"
          :fill="
            nextCityError.length > 0
              ? '#ff5252'
              : isToFieldHover
              ? '#144FA9'
              : next_city && next_city.id
              ? '#144FA9'
              : '#E2E2E2'
          "
          mask="url(#path-1-inside-1_494_353)"
        />
      </svg>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="backgroundTextField"
            style="margin-left: 20px"
            @mouseenter="isToFieldHover = true"
            @mouseleave="isToFieldHover = false"
          >
            <span class="fieldName">{{ "to_label" | localize }}</span>
            <v-text-field
              v-bind="attrs"
              v-on="on"
              color="transparent"
              height="max-content"
              style="margin: 0"
              :placeholder="'to_search_ticket_placeholder' | localize"
              dense
              hide-details
              background-color="transparent"
              class="searchField"
              :value="next_city?.translations?.name"
              @input="filterNextCities"
              :error-messages="nextCityError"
            />
            <span class="countryName"
              >{{ "country_label" | localize }}:
              {{ next_city?.country?.translations?.name }}</span
            >
          </div>
        </template>
        <div class="menuBackground">
          <v-col class="px-0 py-0">
            <p
              class="menuText"
              v-for="city in filteredNextCities"
              :key="city.id"
              style="margin: 8px 0px"
              @click="selectNextCity(city)"
            >
              {{ city?.translations?.name }},
              <span style="color: #989898">{{
                city?.country?.translations?.name
              }}</span>
            </p>
          </v-col>
        </div>
      </v-menu>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <div
            class="backgroundTextField backgroundTextFieldFilledDate"
            style="margin-left: 20px; width: 180px"
            :style="
              date !== '' && date !== null && date !== undefined
                ? ' border-color: #144fa9'
                : ''
            "
          >
            <span class="fieldName">{{ "date_label" | localize }}</span>
            <v-row no-gutters align="center">
              <div style="width: 90px">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  color="transparent"
                  height="max-content"
                  style="margin: 0"
                  placeholder="Сьогодні"
                  dense
                  hide-details
                  readonly
                  background-color="transparent"
                  :value="
                    new Date(date).toLocaleDateString(
                      locale == 'EN'
                        ? 'en-GB'
                        : locale == 'PL'
                        ? 'pl-PL'
                        : 'uk-UA',
                      {
                        month: 'short',
                        day: '2-digit',
                      }
                    )
                  "
                  class="searchField"
                />
              </div>
              <img
                src="@/assets/img/iconsSvg/calendarIcon.svg"
                width="24px"
                height="24px"
                alt="calendar icon"
                style="margin-left: 20px"
              />
            </v-row>
          </div>
        </template>
        <v-date-picker
          color="#144FA9"
          v-model="date"
          :locale="
            locale == 'EN' ? 'en-GB' : locale == 'PL' ? 'pl-PL' : 'uk-UA'
          "
          :min="new Date().toISOString().substring(0, 10)"
          no-title
        />
      </v-menu>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <div
            class="backgroundTextField backgroundTextFieldFilledQuantity"
            style="margin-left: 20px; width: 180px"
            :style="quanityPeople > 0 ? ' border-color: #144fa9' : ''"
          >
            <span class="fieldName">{{
              "quantity_people_label" | localize
            }}</span>
            <v-text-field
              v-bind="attrs"
              v-on="on"
              color="transparent"
              height="max-content"
              style="margin: 0"
              placeholder="01 пасажир"
              dense
              hide-details
              background-color="transparent"
              :value="`${$tc('passenger', quanityPeople)}`"
              class="searchField"
            />
          </div>
        </template>
        <div
          style="
            border-radius: 5px;
            border: 1px solid rgba(181, 181, 181, 0.3);
            background: #fcfcfc;
            padding: 20px;
            width: 230px;
          "
        >
          <v-row no-gutters align="center">
            <v-col cols="6">
              <span class="userType">{{ "adult_label" | localize }}</span>
            </v-col>
            <v-icon
              color="#1B1B1B"
              size="24px"
              style="margin-left: 8px; cursor: pointer"
              @click="quanityPeople > 0 ? quanityPeople-- : ''"
              >mdi-minus-circle-outline</v-icon
            >
            <p style="margin: 0px 8px">{{ quanityPeople }}</p>
            <v-icon
              color="#1B1B1B"
              size="24px"
              style="cursor: pointer"
              @click="quanityPeople++"
              >mdi-plus-circle-outline</v-icon
            >
          </v-row>
        </div>
      </v-menu>
    </div>
    <!-- SEARCH FIELD FOR SMALL DESKTOP SCREEN -->
    <div
      style="width: max-content; display: flex; align-items: center"
      v-else-if="$vuetify.breakpoint.md"
    >
      <div
        class="reverseIcon"
        style="z-index: 10; position: absolute; margin-left: 180px"
        @click="reverseCity"
      />
      <svg
        width="180"
        height="90"
        viewBox="0 0 180 90"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="position: absolute"
      >
        <mask id="path-1-inside-1_1776_51719" fill="white">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 0C4.47715 0 0 4.47715 0 10V80C0 85.5229 4.47716 90 10 90H170C175.523 90 180 85.5229 180 80V66.8236C173.804 63.0314 169.5 54.6857 169.5 45C169.5 35.3143 173.804 26.9686 180 23.1764V10C180 4.47715 175.523 0 170 0H10Z"
          />
        </mask>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 0C4.47715 0 0 4.47715 0 10V80C0 85.5229 4.47716 90 10 90H170C175.523 90 180 85.5229 180 80V66.8236C173.804 63.0314 169.5 54.6857 169.5 45C169.5 35.3143 173.804 26.9686 180 23.1764V10C180 4.47715 175.523 0 170 0H10Z"
          fill="white"
        />
        <path
          d="M180 66.8236H181V66.2631L180.522 65.9706L180 66.8236ZM180 23.1764L180.522 24.0294L181 23.7369V23.1764H180ZM1 10C1 5.02944 5.02944 1 10 1V-1C3.92487 -1 -1 3.92487 -1 10H1ZM1 80V10H-1V80H1ZM10 89C5.02944 89 1 84.9706 1 80H-1C-1 86.0751 3.92487 91 10 91V89ZM170 89H10V91H170V89ZM179 80C179 84.9706 174.971 89 170 89V91C176.075 91 181 86.0751 181 80H179ZM179 66.8236V80H181V66.8236H179ZM180.522 65.9706C174.69 62.4016 170.5 54.4252 170.5 45H168.5C168.5 54.9463 172.917 63.6612 179.478 67.6765L180.522 65.9706ZM170.5 45C170.5 35.5748 174.69 27.5984 180.522 24.0294L179.478 22.3235C172.917 26.3388 168.5 35.0537 168.5 45H170.5ZM179 10V23.1764H181V10H179ZM170 1C174.971 1 179 5.02944 179 10H181C181 3.92487 176.075 -1 170 -1V1ZM10 1H170V-1H10V1Z"
          :fill="
            startCityError.length > 0
              ? '#ff5252'
              : isFromFieldHover
              ? '#144FA9'
              : start_city && start_city.id
              ? '#144FA9'
              : '#E2E2E2'
          "
          mask="url(#path-1-inside-1_1776_51719)"
        />
      </svg>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="backgroundTextField"
            style="width: 180px"
            @mouseenter="isFromFieldHover = true"
            @mouseleave="isFromFieldHover = false"
          >
            <span class="fieldName">{{ "from_label" | localize }}</span>
            <v-text-field
              v-bind="attrs"
              v-on="on"
              color="transparent"
              height="max-content"
              style="margin: 0; width: 140px"
              :placeholder="'from_search_ticket_placeholder' | localize"
              dense
              hide-details
              background-color="transparent"
              class="searchField"
              :value="start_city?.translations?.name"
              @input="filterStartCities"
            />
            <span class="countryName"
              >{{ "country_label" | localize }}:
              {{ start_city?.country?.translations?.name }}</span
            >
          </div>
        </template>
        <div class="menuBackground">
          <v-col class="px-0 py-0">
            <p
              class="menuText"
              v-for="city in filteredStartCities"
              :key="city.id"
              style="margin: 8px 0px"
              @click="selectStartCity(city)"
            >
              {{ city?.translations?.name }},
              <span style="color: #989898">{{
                city?.country?.translations?.name
              }}</span>
            </p>
          </v-col>
        </div>
      </v-menu>
      <svg
        width="180"
        height="90"
        viewBox="0 0 180 90"
        fill="none"
        style="
          position: absolute;
          margin-left: 205px;
          transform: rotate(180deg);
        "
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_1776_51719" fill="white">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 0C4.47715 0 0 4.47715 0 10V80C0 85.5229 4.47716 90 10 90H170C175.523 90 180 85.5229 180 80V66.8236C173.804 63.0314 169.5 54.6857 169.5 45C169.5 35.3143 173.804 26.9686 180 23.1764V10C180 4.47715 175.523 0 170 0H10Z"
          />
        </mask>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 0C4.47715 0 0 4.47715 0 10V80C0 85.5229 4.47716 90 10 90H170C175.523 90 180 85.5229 180 80V66.8236C173.804 63.0314 169.5 54.6857 169.5 45C169.5 35.3143 173.804 26.9686 180 23.1764V10C180 4.47715 175.523 0 170 0H10Z"
          fill="white"
        />
        <path
          d="M180 66.8236H181V66.2631L180.522 65.9706L180 66.8236ZM180 23.1764L180.522 24.0294L181 23.7369V23.1764H180ZM1 10C1 5.02944 5.02944 1 10 1V-1C3.92487 -1 -1 3.92487 -1 10H1ZM1 80V10H-1V80H1ZM10 89C5.02944 89 1 84.9706 1 80H-1C-1 86.0751 3.92487 91 10 91V89ZM170 89H10V91H170V89ZM179 80C179 84.9706 174.971 89 170 89V91C176.075 91 181 86.0751 181 80H179ZM179 66.8236V80H181V66.8236H179ZM180.522 65.9706C174.69 62.4016 170.5 54.4252 170.5 45H168.5C168.5 54.9463 172.917 63.6612 179.478 67.6765L180.522 65.9706ZM170.5 45C170.5 35.5748 174.69 27.5984 180.522 24.0294L179.478 22.3235C172.917 26.3388 168.5 35.0537 168.5 45H170.5ZM179 10V23.1764H181V10H179ZM170 1C174.971 1 179 5.02944 179 10H181C181 3.92487 176.075 -1 170 -1V1ZM10 1H170V-1H10V1Z"
          :fill="
            nextCityError.length > 0
              ? '#ff5252'
              : isToFieldHover
              ? '#144FA9'
              : next_city && next_city.id
              ? '#144FA9'
              : '#E2E2E2'
          "
          mask="url(#path-1-inside-1_1776_51719)"
        />
      </svg>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="backgroundTextField"
            style="margin-left: 20px; width: 180px"
            @mouseenter="isToFieldHover = true"
            @mouseleave="isToFieldHover = false"
          >
            <span class="fieldName">{{ "to_label" | localize }}</span>
            <v-text-field
              v-bind="attrs"
              v-on="on"
              color="transparent"
              height="max-content"
              style="margin: 0; width: 140px"
              :placeholder="'to_search_ticket_placeholder' | localize"
              dense
              hide-details
              background-color="transparent"
              class="searchField"
              :value="next_city?.translations?.name"
              @input="filterNextCities"
            />
            <span class="countryName"
              >{{ "country_label" | localize }}:
              {{ next_city?.country?.translations?.name }}</span
            >
          </div>
        </template>
        <div class="menuBackground">
          <v-col class="px-0 py-0">
            <p
              class="menuText"
              v-for="city in filteredNextCities"
              :key="city.id"
              style="margin: 8px 0px"
              @click="selectNextCity(city)"
            >
              {{ city?.translations?.name }},
              <span style="color: #989898">{{
                city?.country?.translations?.name
              }}</span>
            </p>
          </v-col>
        </div>
      </v-menu>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <div
            class="backgroundTextField backgroundTextFieldFilledDate"
            style="margin-left: 20px; width: 160px"
            :style="
              date !== '' && date !== null && date !== undefined
                ? ' border-color: #144fa9'
                : ''
            "
          >
            <span class="fieldName">{{ "date_label" | localize }}</span>
            <v-row no-gutters align="center">
              <div style="width: 90px">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  color="transparent"
                  height="max-content"
                  style="margin: 0"
                  placeholder="Сьогодні"
                  dense
                  hide-details
                  readonly
                  background-color="transparent"
                  :value="
                    new Date(date).toLocaleDateString(
                      locale == 'EN'
                        ? 'en-GB'
                        : locale == 'PL'
                        ? 'pl-PL'
                        : 'uk-UA',
                      {
                        month: 'short',
                        day: '2-digit',
                      }
                    )
                  "
                  class="searchField"
                />
              </div>
              <img
                src="@/assets/img/iconsSvg/calendarIcon.svg"
                width="24px"
                height="24px"
                alt="calendar icon"
                style="margin-left: 0px"
              />
            </v-row>
          </div>
        </template>
        <v-date-picker
          color="#144FA9"
          v-model="date"
          :locale="
            locale == 'EN' ? 'en-GB' : locale == 'PL' ? 'pl-PL' : 'uk-UA'
          "
          :min="new Date().toISOString().substring(0, 10)"
          no-title
        />
      </v-menu>
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <div
            class="backgroundTextField backgroundTextFieldFilledQuantity"
            style="margin-left: 20px; width: 140px"
            :style="quanityPeople > 0 ? ' border-color: #144fa9' : ''"
          >
            <span class="fieldName">{{
              "quantity_people_label" | localize
            }}</span>
            <v-text-field
              v-bind="attrs"
              v-on="on"
              color="transparent"
              height="max-content"
              style="margin: 0"
              placeholder="01 пасажир"
              dense
              hide-details
              background-color="transparent"
              :value="`${$tc('passenger', quanityPeople)}`"
              class="searchField"
            />
          </div>
        </template>
        <div
          style="
            border-radius: 5px;
            border: 1px solid rgba(181, 181, 181, 0.3);
            background: #fcfcfc;
            padding: 20px;
            width: 230px;
          "
        >
          <v-row no-gutters align="center">
            <v-col cols="6">
              <span class="userType">{{ "adult_label" | localize }}</span>
            </v-col>
            <v-icon
              color="#1B1B1B"
              size="24px"
              style="margin-left: 8px; cursor: pointer"
              @click="quanityPeople > 0 ? quanityPeople-- : ''"
              >mdi-minus-circle-outline</v-icon
            >
            <p style="margin: 0px 8px">{{ quanityPeople }}</p>
            <v-icon
              color="#1B1B1B"
              size="24px"
              style="cursor: pointer"
              @click="quanityPeople++"
              >mdi-plus-circle-outline</v-icon
            >
          </v-row>
        </div>
      </v-menu>
    </div>
    <!-- SEARCH FIELD FOR MOBILE SCREEN -->
    <div v-else style="width: 100%">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="backgroundTextField"
            :style="start_city && start_city.id ? 'border-color:#144FA9' : ''"
            @mouseenter="isFromFieldHover = true"
            @mouseleave="isFromFieldHover = false"
          >
            <span class="fieldName">{{ "from_label" | localize }}</span>
            <v-text-field
              v-bind="attrs"
              v-on="on"
              color="transparent"
              height="max-content"
              style="margin: 0"
              :placeholder="'from_search_ticket_placeholder' | localize"
              dense
              hide-details
              background-color="transparent"
              class="searchField"
              :value="start_city?.translations?.name"
              @input="filterStartCities"
            />
            <span class="countryName"
              >{{ "country_label" | localize }}:
              {{ start_city?.country?.translations?.name }}</span
            >
          </div>
        </template>
        <div class="menuBackground">
          <v-col class="px-0 py-0">
            <p
              class="menuText"
              v-for="city in filteredStartCities"
              :key="city.id"
              style="margin: 8px 0px"
              @click="selectStartCity(city)"
            >
              {{ city?.translations?.name }},
              <span style="color: #989898">{{
                city?.country?.translations?.name
              }}</span>
            </p>
          </v-col>
        </div>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="backgroundTextField"
            :style="next_city && next_city.id ? 'border-color:#144FA9' : ''"
            @mouseenter="isFromFieldHover = true"
            @mouseleave="isFromFieldHover = false"
          >
            <span class="fieldName">{{ "to_label" | localize }}</span>
            <v-text-field
              v-bind="attrs"
              v-on="on"
              color="transparent"
              height="max-content"
              style="margin: 0"
              :placeholder="'from_search_ticket_placeholder' | localize"
              dense
              hide-details
              background-color="transparent"
              class="searchField"
              :value="next_city?.translations?.name"
              @input="filterNextCities"
            />
            <span class="countryName"
              >{{ "country_label" | localize }}:
              {{ next_city?.country?.translations?.name }}</span
            >
          </div>
        </template>
        <div class="menuBackground">
          <v-col class="px-0 py-0">
            <p
              class="menuText"
              v-for="city in filteredNextCities"
              :key="city.id"
              style="margin: 8px 0px"
              @click="selectNextCity(city)"
            >
              {{ city?.translations?.name }},
              <span style="color: #989898">{{
                city?.country?.translations?.name
              }}</span>
            </p>
          </v-col>
        </div>
      </v-menu>
      <v-row no-gutters>
        <v-col class="py-0" style="padding-right: 2px">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="backgroundTextField backgroundTextFieldFilledDate"
                style="width: 100%"
                :style="
                  date !== '' && date !== null && date !== undefined
                    ? ' border-color: #144fa9'
                    : ''
                "
              >
                <span class="fieldName">{{ "date_label" | localize }}</span>
                <v-row no-gutters align="center">
                  <div style="width: 90px">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      color="transparent"
                      height="max-content"
                      style="margin: 0"
                      placeholder="Сьогодні"
                      dense
                      hide-details
                      readonly
                      background-color="transparent"
                      :value="
                        new Date(date).toLocaleDateString(
                          locale == 'EN'
                            ? 'en-GB'
                            : locale == 'PL'
                            ? 'pl-PL'
                            : 'uk-UA',
                          {
                            month: 'short',
                            day: '2-digit',
                          }
                        )
                      "
                      class="searchField"
                    />
                  </div>
                  <v-row no-gutters justify="end">
                    <img
                      src="@/assets/img/iconsSvg/calendarIcon.svg"
                      width="24px"
                      height="24px"
                      alt="calendar icon"
                    />
                  </v-row>
                </v-row>
              </div>
            </template>
            <v-date-picker
              color="#144FA9"
              v-model="date"
              :locale="
                locale == 'EN' ? 'en-GB' : locale == 'PL' ? 'pl-PL' : 'uk-UA'
              "
              :min="new Date().toISOString().substring(0, 10)"
              no-title
            />
          </v-menu>
        </v-col>
        <v-col class="py-0" style="padding-left: 2px">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="backgroundTextField backgroundTextFieldFilledQuantity"
                style="width: 100%"
                :style="quanityPeople > 0 ? ' border-color: #144fa9' : ''"
              >
                <span class="fieldName">{{
                  "quantity_people_label" | localize
                }}</span>
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  color="transparent"
                  height="max-content"
                  style="margin: 0"
                  placeholder="01 пасажир"
                  dense
                  hide-details
                  background-color="transparent"
                  :value="`${$tc('passenger', quanityPeople)}`"
                  class="searchField"
                />
              </div>
            </template>
            <div
              style="
                border-radius: 5px;
                border: 1px solid rgba(181, 181, 181, 0.3);
                background: #fcfcfc;
                padding: 20px;
                width: 230px;
              "
            >
              <v-row no-gutters align="center">
                <v-col cols="6">
                  <span class="userType">{{ "adult_label" | localize }}</span>
                </v-col>
                <v-icon
                  color="#1B1B1B"
                  size="24px"
                  style="margin-left: 8px; cursor: pointer"
                  @click="quanityPeople > 0 ? quanityPeople-- : ''"
                  >mdi-minus-circle-outline</v-icon
                >
                <p style="margin: 0px 8px">{{ quanityPeople }}</p>
                <v-icon
                  color="#1B1B1B"
                  size="24px"
                  style="cursor: pointer"
                  @click="quanityPeople++"
                  >mdi-plus-circle-outline</v-icon
                >
              </v-row>
            </div>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <v-btn
      class="searchBtn"
      :style="
        $vuetify.breakpoint.md
          ? 'min-width: 40px!important;width: 135px; margin-left: 15px; font-size: 16px;'
          : ''
      "
      @click="searchTicket"
      >{{ "search_ticket_btn" | localize }}</v-btn
    >
  </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import searchRoutesService from "../../../requests/User/searchRoutesService";
export default {
  mixins: [validationMixin],
  data: () => ({
    isFromFieldHover: false,
    isToFieldHover: false,
    date: new Date().toISOString().substring(0, 10),
    quanityPeople: 1,
    menu: false,
    searchQuery: "",
    filteredStartCities: [],
    filteredNextCities: [],
    startCities: [],
    nextCities: [],
    start_city: {
      translations: {
        name: "",
      },
    },
    next_city: {
      translations: {
        name: "",
      },
    },
  }),
  validations: {
    start_city: {
      id: { required },
    },
    next_city: {
      id: {
        required,
      },
    },
    date: {
      required,
    },
  },
  props: {
    departure_city: {
      require: false,
    },
    arrival_city: {
      require: false,
    },
  },
  mounted() {
    this.getStartCities();
  },
  methods: {
    searchTicket() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$router.push(
          `/search/${this.start_city.id}/${this.next_city.id}?departureDate=${this.date}&qty=${this.quanityPeople}`
        );
      }
    },
    async getStartCities() {
      await searchRoutesService.getStartCities().then((res) => {
        if (res.status == "Success") {
          this.startCities = res.data.sort((a, b) =>
            a?.translations?.name.localeCompare(b?.translations?.name)
          );
          this.filteredStartCities = this.startCities;
          if (this.$route.name == "ticket_search") {
            this.setSearchField();
          }
          if (this.$route.name == "route_detail_page") {
            this.start_city = this.startCities.find(
              (city) => city.id == this.departure_city
            );
            this.next_city = this.startCities.find(
              (city) => city.id == this.arrival_city
            );
          }
        }
      });
    },
    async getNextCities() {
      await searchRoutesService
        .getNextCities(this.start_city.id)
        .then((res) => {
          if (res.status == "Success") {
            this.nextCities = res.data.sort((a, b) =>
              a?.translations?.name.localeCompare(b?.translations?.name)
            );
            this.filteredNextCities = res.data.sort((a, b) =>
              a?.translations?.name.localeCompare(b?.translations?.name)
            );
          }
        });
    },
    setSearchField() {
      this.start_city = this.startCities.find(
        (city) => city.id == this.$route.params.departure_city
      );
      this.next_city = this.startCities.find(
        (city) => city.id == this.$route.params.arrival_city
      );
      this.date = this.$route?.query?.departureDate;
      this.quanityPeople = this.$route?.query?.qty;
    },
    reverseCity() {
      const tempCity = this.start_city;
      this.start_city = this.next_city;
      this.next_city = tempCity;
    },
    filterStartCities(e) {
      if (e) {
        this.filteredStartCities = this.startCities.filter((city) =>
          city.translations.name.toLowerCase().includes(e.toLowerCase())
        );
      } else {
        this.filteredStartCities = [...this.startCities];
      }
    },
    filterNextCities(e) {
      if (e) {
        this.filteredNextCities = this.nextCities.filter((city) =>
          city.translations.name.toLowerCase().includes(e.toLowerCase())
        );
      } else {
        this.filteredNextCities = [...this.nextCities];
      }
    },
    selectStartCity(city) {
      this.start_city = city;
      this.menu = false;
      setTimeout(() => {
        this.filteredStartCities = [...this.startCities];
      }, 100);
    },
    selectNextCity(city) {
      this.next_city = city;
      this.menu = false;
      setTimeout(() => {
        this.filteredNextCities = [...this.nextCities];
      }, 100);
    },
  },
  computed: {
    ...mapGetters(["locale"]),
    startCityError() {
      const errors = [];
      let field = this.$v.start_city.id;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    nextCityError() {
      const errors = [];
      let field = this.$v.next_city.id;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
  },
  watch: {
    searchQuery(newQuery) {
      if (newQuery) {
        this.menu = true; // Відкриваємо меню при наявності тексту
      } else {
        this.menu = false; // Закриваємо меню, якщо поле порожнє
      }
    },
    start_city: {
      deep: true,
      handler() {
        if (
          this.start_city !== null &&
          this.start_city !== undefined &&
          this.start_city !== ""
        ) {
          this.getNextCities();
        }
      },
    },
    "$route.path": {
      deep: true,
      handler() {
        this.setSearchField();
      },
    },
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1024px) {
  .backgroundTextField {
    height: 90px;
    width: 240px;
    z-index: 10;
    padding: 5px 20px;
  }
  .backgroundTextFieldFilledDate {
    border-radius: 5px;
    border: 1px solid #e2e2e2;

    background: #fff;
  }
  .backgroundTextFieldFilledDate:hover {
    border-color: #144fa9;
  }
  .backgroundTextFieldFilledQuantity {
    border-radius: 5px;
    border: 1px solid #e2e2e2;

    background: #fff;
  }
  .backgroundTextFieldFilledQuantity:hover {
    border-color: #144fa9;
  }
  .fieldName {
    color: var(--Black, #1b1b1b);
    font-family: "MacPaw Fixel Display";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .countryName {
    color: #989898;
    font-family: "MacPaw Fixel Display";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .menuBackground {
    border-radius: 5px;
    border: 1px solid rgba(181, 181, 181, 0.3);
    background: #fafafa;
    padding: 8px 16px;
    max-height: 200px;
    overflow: scroll;
  }
  .menuText {
    color: #1b1b1b;
    font-family: "MacPaw Fixel Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
  .searchBtn {
    text-transform: none;
    border-radius: 10px;
    background: #144fa9 !important;
    min-width: 180px !important;
    height: 90px !important;
    padding: 19px 53px;
    color: #fafafa;
    font-family: "MacPaw Fixel";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 20px;
  }
  .userType {
    color: #1b1b1b;
    font-family: "MacPaw Fixel Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
@media only screen and (max-width: 1023px) {
  .backgroundTextField {
    height: 90px;
    width: 100%;
    z-index: 10;
    padding: 5px 20px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    margin-bottom: 4px;
  }
  .backgroundTextFieldFilledDate {
    border-radius: 10px;
    border: 1px solid #e2e2e2;
    background: #fff;
  }
  .backgroundTextFieldFilledDate:hover {
    border-color: #144fa9;
  }
  .backgroundTextFieldFilledQuantity {
    border-radius: 10px;
    border: 1px solid #e2e2e2;
    background: #fff;
  }
  .backgroundTextFieldFilledQuantity:hover {
    border-color: #144fa9;
  }
  .fieldName {
    color: var(--Black, #1b1b1b);
    font-family: "MacPaw Fixel Display";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .countryName {
    color: #989898;
    font-family: "MacPaw Fixel Display";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .menuBackground {
    border-radius: 5px;
    border: 1px solid rgba(181, 181, 181, 0.3);
    background: #fafafa;
    padding: 8px 16px;
    max-height: 200px;
    overflow: scroll;
  }
  .menuText {
    color: #1b1b1b;
    font-family: "MacPaw Fixel Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
  .searchBtn {
    text-transform: none;
    border-radius: 10px;
    background: #144fa9 !important;
    width: 100% !important;
    height: 90px !important;
    padding: 19px 53px;
    color: #fafafa;
    font-family: "MacPaw Fixel";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .userType {
    color: #1b1b1b;
    font-family: "MacPaw Fixel Display";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
<style>
.searchField.v-text-field input {
  color: #1b1b1b;
  font-family: "MacPaw Fixel Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: transparent;
}
.theme--light.v-input {
  border-color: transparent;
}
.theme--light.v-input input,
.theme--light.v-input textarea {
  border-color: transparent;
}
</style>