<template>
  <v-footer class="footerForAllPages">
    <v-main>
      <v-container>
        <v-row no-gutters align="center">
          <v-col class="px-0 py-0" cols="12" xl="4" lg="4" md="4" sm="12">
            <span class="footerText"
              >© 2023-{{ new Date().getFullYear() }} P-Trans.
              {{ "footer_all_right_reserved" | localize }}</span
            >
          </v-col>
          <v-col class="px-0 py-0" cols="12" xl="4" lg="4" md="4" sm="12">
            <v-row
              no-gutters
              align="center"
              justify="center"
              :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 24px' : ''"
            >
              <span
                style="
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 19px;
                  color: #eeeeee;
                  margin-left: 10px;
                  margin-right: 8px;
                "
                >Developed by</span
              >
              <a
                href="https://www.bmax.com.ua/"
                target="_blank"
                style="margin-top: 6px;"
              >
                <img
                  src="@/assets/img/BMaxLogo.png"
                  width="80px"
                  height="40px"
                  style="object-fit: cover; margin-left: 8px"
                  alt="bmax logo"
                />
              </a>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-footer>
</template>

<script>
export default {};
</script>

<style scoped>
.footerForAllPages {
  background: #031755 !important;
}
.footerText {
  color: #989898;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>